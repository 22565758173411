.ht-title {
  color: #81143c;
  font-family: 'Quicksand';
}

.ht-font {
  font-family: 'Quicksand';
}

body {
  background-color: #f9e9ef;
}